.ascii {
    overflow-x: clip;
    /* position: fixed; */
    top: 0;
    width: 100vw;
    height: 100vh;
    min-height: 30vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);
    z-index: 1000000000;
    background: linear-gradient(180deg, #272e3a 0%, #000 100%, transparent 100%);
}

.ascii pre {
    
    display: inline-block;
    text-align: center;
    vertical-align: center;
    font-size: 0.48vw;
    line-height: 0.48vw;
    font-family: 'Courier New', Courier, monospace;
    padding-bottom: 18px;
    transition: all ease-out 0.5s;
}
