@import url("https://fonts.googleapis.com/css2?family=Electrolize&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
:root {
  --black: #221f1f;
  --white: #fcfcfc;
  --skyel-blue: #05b0fe;
  --deverick-blue: #2a323f;
  --deverick-grey: #293241;
  --deverick-orange: #f3a057;
  --footer-grey: #d9d9d9;
}

html {
  color: var(--black);
  background-color: #2a323f;
}

body {
  height: 700vh;
  max-height: 70vh;
}

html {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
html::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

* {
  user-select: none;
}

canvas {
  cursor: grab;
}