.welcome {
  position: relative;
  height: 100vh;
  /* padding: 2.6vh 0 0 0; */
  padding: 0 5%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: black;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center 0;
}

.content,
.imgs {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.imgs > img {
  display: block;
  min-width: 500px;
  position: absolute;
  right: -150px;
  top: -158px;
}

.content {
  width: 70vw;
  align-items: center;
}

.header {
  display: flex;
}

.header h1 {
  color: #fff;
  font-family: Electrolize;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: -4%;
}

.dev {
  display: block;
  min-width: 420px;
  flex-shrink: 0;
  margin-top: -11%;
  margin-left: 3%;
}

.content p {
  color: #fff;
  font-family: Electrolize;
  font-size: 25px;
  font-weight: 400;
  padding-left: 14%;
  margin-top: 40px;
}

.content > img {
  display: block;
  height: 39vw;
  animation: pulse 5s infinite;
  transform: scale(0.8);
}

.underline {
  display: inline-block;
  font-style: normal;
  position: relative;
}

.underline::after {
  content: "";
  display: block;
  width: 100%;
  height: 1.5px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  bottom: 3px;
  transform: translateX(-50%);
}
