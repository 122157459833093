.container {
    background-color: black;
}

.info {
    height: 85vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.logo, .text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.model {
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tiles {
    height: 15vh;
    display: flex;
    justify-content: space-evenly;
}

.project {
    width: 200px;
    cursor: pointer;
}

.preview {
    height: 80px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 8px;
}

.name {
    margin-top: 7px;
    color: white;
    text-align: center;
}