.underheader {
  width: 100%;
  min-height: 120vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.quoteBlock {
  /* z-index: 1; */
  padding: 197px 0 197px 100px;
  /* margin-top: -20vh; */
}

.quoteBlock>p {
  white-space: pre;
  line-height: 150%;
  border-left: 16px solid var(--deverick-orange);
  padding-left: 22px;
}

.blackStripes {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: none;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}


.h2 {
  font-size: 64px;
  text-align: center;
  margin-bottom: 100px;
} 

.projectsContainer {
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 67%;
  margin: auto;
}

.team {
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 197px;
  width: 67%;
}

.teamContainer {
  display: flex;
  flex-direction: column;
}