.container {
    position: fixed;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 255, 0, 0.7);
    transform: translateY(100vh);
    transition: all ease-out 0.5s;
}

.content {
    height: 200px;
    width: 100px;
    margin-right: 100px;
    border: 4px solid black;
}