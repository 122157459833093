.header {
  position: fixed;
  top: -120px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 60px;
  padding: 0 50px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  border-radius: 0px 0px 10px 10px;
  background: rgba(0, 0, 0, 0.70);
  box-shadow: 0px 0px 23px 12px rgba(0, 0, 0, 0.80);
  transition: all ease-out 0.5s;
}

.logo {
  cursor: pointer;
}

.header.show {
  top: 20px;
}

.nav {
  color: white;
  display: flex;
  width: 40%;
  justify-content: space-evenly;
  align-items: center;
}

.nav li {
  list-style: none;
}

.nav li a {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}