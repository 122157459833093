.welcome {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: black;
}

.welcome div {
  width: 50vw;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-sizing: border-box;
  padding-left: 50px;
  align-self: flex-end;
  padding-bottom: 150px;
}

.text h1 {
  color: #fff;
  font-family: Electrolize;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text p {
  color: #fff;
  font-family: Electrolize;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 314px;
  height: 70px;
  border-radius: 10px;
  background: #b0ccf0;
  box-shadow: 0px 0px 45.9px 2px #6a8fff;
  color: #262c7a;
  text-align: center;
  font-family: Rubik;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.model canvas {
    height: 100vh;
}