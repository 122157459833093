.footer {
    height: 200px;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 30px auto;
    background-color: var(--deverick-grey);
    /* margin-top: 200px; */
    align-items: center;
    padding: 28px 10% 0 10%;
    column-gap: 40px;
    row-gap: 20px;
    grid-template-areas: 
    "h2 h22"
    "switches icons";
}

.icon, .switches {
    margin-top: -20px;
}

.switches {
    margin-top: -53px;
}

.h2, .h22 {
    grid-area: h2;
    color: var(--white);
    font-size: 18px;
    text-align: center;
}

.h22 {
    grid-area: h22;
}

.icons {
    grid-area: icons;
    display: flex;
    justify-content: space-between;
    /* padding-top: 32px; */
}

.icons::before {
    content: "";
    width: 2px;
    height: 100px;
    background-color: #b6b6b6;
    margin-left: -10px;
    border-radius: 12px;
    transform: translateY(-20px);
}

.iconWrapper {
    color: var(--white);
    font-size: 11px;
    width: 110px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    line-height: 140%;
}

.icon {
    width: 55px;
    /* height: 100px; */
    /* background-color: var(--skyel-blue); */
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.icon:hover {
    transform: scale(1.1);
}

.switches {
    grid-area: switches;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    justify-items: center;
    /* border-right: 3px solid var(--white); */
}

/* Стилизация переключателя */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    justify-self: center;
}

.switchWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.switchWrapper span {
    color: var(--white);
    font-size: 12px;
    letter-spacing: 1px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9c9c9c;
    transition: .4s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: rgb(221, 221, 221);
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--deverick-orange);
}

input:checked+.slider:before {
    transform: translateX(30px);
}

.switchLabel {
    display: inline-block;
    width: 70px;
}